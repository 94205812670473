
@media only screen and (max-width: 767px) {

    .horizontal-scrollable-tabs {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        display: flex !important;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: thin;  /* Firefox */
    }

    /* width */
    .horizontal-scrollable-tabs::-webkit-scrollbar {
        height: 2px;
    }

    /* Track */
    .horizontal-scrollable-tabs::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px lightgray;
        border-radius: 10px;
    }

    /* Handle */
    .horizontal-scrollable-tabs::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 10px;
    }

    .horizontal-scrollable-tabs .nav-tabs {
        white-space: nowrap;
        display: inline-flex;
    }

    .horizontal-scrollable-tabs .nav-item {
        flex-shrink: 0;
    }

    .horizontal-scrollable-tabs .nav-link {
        padding: 0.5rem 1rem;
    }
}

#nav-dropdown-sports-center {
    color: white;
    background-color: #0d6efd;
    border-radius: 5px;
    padding: 5px 25px 5px 25px;
    margin:  10px 0 10px 0;
}