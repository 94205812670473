.add {
  opacity: 0;
}

.add:hover {
  opacity: 0.5;
}

.hours {
  table-layout: fixed;
  margin-top: auto;
  margin-bottom: 6px;
  width: 100px;
}

.border-header {
  border: solid 1px #dee2e6;
}

.border-outside {
  -webkit-box-shadow:
          inset -2px 0px 0px #dee2e6,
          inset 2px 0px 0px #dee2e6,
          inset 0px 2px 0px #dee2e6;
  -moz-box-shadow:
          inset -2px 0px 0px #dee2e6,
          inset 2px 0px 0px #dee2e6,
          inset 0px 2px 0px #dee2e6;

  box-shadow:
          inset -2px 0px 0px #dee2e6,
          inset 2px 0px 0px #dee2e6,
          inset 0px 2px 0px #dee2e6;
}

.reservation {
  position: absolute;
  height: 34px;
  width: 200px;
  border-color: darkgray;
  z-index: 1;
}

.reservations {
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 100%;
}

.reservations-table {
  table-layout: fixed!important;
  width: unset!important;
  border-collapse: separate;
  border-spacing: 0;
  display: table;
}

.reservations-col {
  height: 35px;
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  text-align: center;
  vertical-align: middle;
}

.reservations-first-col {
  left: 0;
  z-index: 2;
  width: 60px!important;
  max-width: 60px!important;
  min-width: 60px!important;
  text-align: right;
  border: none;
  background-color: #f8f9fa!important;
  height: 35px;
  vertical-align: middle;
}

.reservations-first-col-first-row {
  left: 0;
  z-index: 2;
  width: 60px!important;
  max-width: 60px!important;
  min-width: 60px!important;
  border: none;
  height: 35px;
  text-align: center;
  vertical-align: middle;
  background-color: #f8f9fa!important;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.date-header-container {
  font-size: 1.5rem;
}

.date-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-buttons-container .button-text-below {
  font-weight: 550;
  position: absolute;
  margin-top: 3.6rem;
  width: 10vw;
  max-width: 4rem;
  text-align: center;
  color: #6C757D;
  font-size: .875em;
}

.date-buttons-container button {
  width: 10vw;
  max-width: 4rem;
}

.date-buttons-container .button-header {
  font-size: calc(1.275rem + .3vw);
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.date-buttons-container .button-body {
  margin-top: -0.75rem;
  font-size: .875em;
}


@media only screen and (min-width: 577px) and (max-width: 767px) {
  .date-header-container {
    font-size: 1.4rem;
  }

  .date-buttons-container {
    flex-wrap: nowrap; /* Prevent wrapping on smaller screens */
  }

  .date-buttons-container .button-text-below {
    font-size: .7em;
  }

  .date-buttons-container .button-header {
    font-size: calc(1.275rem + .3vw);
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .date-buttons-container .button-body {
    margin-top: -0.75rem;
    font-size: .875em;
  }
}

@media only screen and (max-width: 576px) {
  .date-header-container {
    font-size: 1.2rem;
  }

  .date-buttons-container {
    flex-wrap: nowrap; /* Prevent wrapping on smaller screens */
  }

  .date-buttons-container .button-text-below {
    margin-top: 3rem;
    font-size: .6em;
  }

  .date-buttons-container button {
    padding-left: 0;
    padding-right: 0;
  }

  .date-buttons-container .button-header {
    font-size: calc(1rem + .3vw);
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .date-buttons-container .button-body {
    margin-top: -0.75rem;
    font-size: .7em;
  }

}