.offer-modal-body {
  max-height: 75vh;
  overflow: scroll;

}

.offer-modal-body td {
  width: 33%
}

@media(max-width: 768px) {
  .offer-modal-body td {
    padding: 4px;
  }
}

.offer-modal-body svg {
  vertical-align: middle;
  font-size: 1.33rem;
}