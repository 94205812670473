body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.css-honxw6 {
    display: inline-block;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    border-right: 2px solid currentcolor;
    border-bottom: 2px solid currentcolor;
    width: 7px !important;
    height: 7px !important;
    -webkit-transform: rotate(45deg) !important;
    -moz-transform: rotate(45deg) !important;
    -ms-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
    margin-bottom: 4px;
}

.css-jn69v9 {
    display: inline-block;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    border-right: 2px solid currentcolor;
    border-bottom: 2px solid currentcolor;
    width: 7px !important;
    height: 7px !important;
    -webkit-transform: rotate(-135deg) !important;
    -moz-transform: rotate(-135deg) !important;
    -ms-transform: rotate(-135deg) !important;
    transform: rotate(-135deg) !important;
}

.css-8i0j0q {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.1) !important;
}
@media only screen and (max-width: 767px) {
    .ps-menu-button:hover {
        background-color: inherit !important;
    }
}

.ps-submenu-root> .ps-menu-button {
    border-bottom:  1px solid #F5F7FA
}

.ps-submenu-root:first-child > .ps-menu-button {
    border-top:  1px solid #F5F7FA
}

.sidebar-radius div:first-child {
    border-radius: 0 16px 16px 0 !important;
}

.navbar-toggler {
    font-size: 1.25rem;
    line-height: 1;
    color: rgba( 0,0,0 , 0.65);
    background-color: transparent;
    border: 1px solid rgba( 0,0,0 , 0.15);
    border-radius: 0.375rem;
    transition: box-shadow 0.15s ease-in-out;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.dropdown-item.active, .dropdown-item:active {
    color: inherit;
    background-color: inherit;
}


/* Sport Router Table */
.sr-table th, .sr-table thead {
    background-color: #e2e3e5 !important;
}

.sr-table tbody, .sr-table thead {
    vertical-align: middle !important;
}

.sr-table tr:last-child {
    border-bottom: transparent !important;
}
.sr-table th:first-child, .sr-table td:first-child {
    padding-left: 24px !important;
}


.sr-table th:first-child, .sr-table td:first-child {
    padding-left: 24px !important;
}
.sr-table th:last-child, .sr-table td:last-child {
    padding-right: 24px !important;
}

.sr-table th:first-child {
    border-top-left-radius: 5px !important;
}

.sr-table th:last-child {
    border-top-right-radius: 5px !important;
}

.sr-table tr:last-child td:first-child {
    border-bottom-left-radius: 5px !important;
}

.sr-table tr:last-child td:last-child {
    border-bottom-right-radius: 5px !important;
}
.table-pointer tbody {
    cursor: pointer !important;
}

.switch-danger .form-check-input:checked {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.form-check-input {
    cursor: pointer;
}